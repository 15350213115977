$(window).scroll(function () {
	$(".waves").each(function () {
		var imagePos = $(this).offset().top;
		var topOfWindow = $(window).scrollTop();

		if (imagePos < topOfWindow + $(window).height() / 1.4) {
			$(".wave").each(function (index) {
				var wave = $(this);
				setTimeout(function () {
					wave.addClass("animate");
				}, index * 350);
			});
		}
	});
});

$(".dialog-trigger").click(function () {
	const dialogID = $(this).data("target");
	const dialog = $(`.dialog[data-id='${dialogID}']`);

	dialog.fadeIn();

	setTimeout(() => {
		dialog.find(".backdrop").addClass("visible");
	}, 150);

	setTimeout(() => {
		dialog.find(".window").addClass("visible");
	}, 500);
});

$(".dialog .close-popup").click(function () {
	$(".dialog .window").removeClass("visible");

	setTimeout(function () {
		$(".dialog .backdrop").removeClass("visible");
	}, 150);

	setTimeout(function () {
		$(".dialog").fadeOut();
	}, 750);
});
